<template>
  <div id="allTeacher" class="teacher mx-3 mt-5">
    <b-row align-content="between">
      <b-col class="mt-0 text-center text-md-left" cols="12" md="3">
        <span style="font-weight: bold; font-size: 24px" class="mx-1">
          ผู้สอน
        </span>
      </b-col>
      <b-col cols="12" md="6">
        <!-- <Search placeholder="ค้นหาคุณครู..." @search="searchTeacherHandler" /> -->
      </b-col>
      <b-col
        class="my-auto text-center text-md-right pb-3 pb-md-0 mb-3"
        cols="12"
        md="3"
      >
        <b-button-group>
          <b-button @click="prevCarousel()" :variant="'warning'">
            <b-icon fab icon="arrow-left" class="close"></b-icon>
          </b-button>
          <b-button @click="nextCarousel()" :variant="'warning'">
            <b-icon fab icon="arrow-right" class="close"></b-icon>
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <swiper ref="carousel" @click-slide="handlerSlide" :options="swiperOptions">
      <swiper-slide
        v-for="creator in teacherList"
        class="card-teacher pointer"
        :key="creator.id"
        @click-slide="teacherDetail(creator.id)"
        @reachEnd="endSlide"
      >
        <b-row>
          <b-col cols="12" md="auto" class="text-center">
            <b-avatar
              :src="creator.profile_image"
              size="8rem"
              class="mx-3 my-3"
            ></b-avatar>
          </b-col>
          <b-col class="py-3">
            <div
              style="overflow-x: hidden"
              class="mb-2 text-center text-md-left"
            >
              <span style="overflow-x: hidden" class="font-weight-bold">
                {{ creator.first_name + " " + creator.last_name }}
              </span>
            </div>
            <div class="text-center text-md-left">
              <span style="overflow-x: hidden; font-size: 14px">
                {{ creator.teacher_position }}
              </span>
            </div>
          </b-col>
        </b-row>
      </swiper-slide>
    </swiper>

    <!-- <VueSlickCarousel
      v-if="isShowCreator"
      v-bind="settings"
      :key="indexCarousel"
      :arrows="false"
      ref="carousel"
    >
      <div
        class="card-teacher pointer"
        v-for="creator in teacherList"
        :key="creator.id"
        @click="teacherDetail(creator.id)"
      >
        <b-row>
          <b-col cols="auto">
            <b-avatar
              :src="creator.profile_image"
              size="8rem"
              class="mx-3 my-3"
            ></b-avatar>
          </b-col>
          <b-col class="py-3">
            <div style="overflow-x: hidden" class="mb-2">
              <span style="overflow-x: hidden" class="font-weight-bold">
                {{ creator.first_name + " " + creator.last_name }}
              </span>
            </div>
            <div>
              <span style="overflow-x: hidden; font-size: 14px">
                {{ creator.short_description }}
              </span>
            </div>
          </b-col>
        </b-row>
      </div>
    </VueSlickCarousel> -->
  </div>
</template>
<script>
import img from "../assets/image/mock/no-image.png";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// import { Carousel, Slide } from "vue-carousel";
// import VueSlickCarousel from "vue-slick-carousel";
import { mapState } from "vuex";
// import Search from "@/components/search";

export default {
  name: "Creator",
  props: {
    dataCreator: null,
  },
  computed: {
    ...mapState({
      // teacherList: (state) => {
      //   return state.Teachers.teachersList;
      // },
    }),
  },
  async created() {
    await this.getContent();
  },
  data() {
    return {
      teacherList: [],
      swiperOptions: {
        breakpoints: {
          // when window width is >= 320px
          1400: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          // when window width is >= 480px
          576: {
            slidesPerView: 1,
            spaceBetween: 30,
            height: 600,
          },
          // when window width is >= 640px
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
        },
      },
      indexCarousel: 0,
      isShowCreator: true,
      img: img,
      settings: {
        focusOnSelect: false,
        arrows: false,
        infinite: false,
        // "initialSlide": 2,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        // "autoplay": true,
        ltr: true,
        // "autoplaySpeed": 3000,
        cssEase: "linear",
        variableWidth: true,
        responsive: [
          {
            breakpoint: 2560,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              // "centerMode": true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 375,
            settings: {
              // centerMode: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  components: {
    // VueSlickCarousel,
    // Search,
    // Carousel,
    // Slide,
  },
  methods: {
    endSlide(object) {
      console.log("endslide", object);
    },
    handlerSlide(object) {
      const teacher = this.teacherList[object];
      this.teacherDetail(teacher.id);
    },
    prevCarousel() {
      this.$refs.carousel.$swiper.slidePrev();
    },
    nextCarousel() {
      this.$refs.carousel.$swiper.slideNext();
    },
    async searchTeacherHandler(text) {
      await this.$emit("search", text);
      this.indexCarousel += 1;
      this.$forceUpdate();
    },
    async getContent() {
      // await this.$store.dispatch("Teachers/TeachersList");
      this.teacherList = await this.$store.dispatch(
        "Teachers/getIndexTeachers"
      );

      console.log(this.teacherList);
    },
    async teacherDetail(id) {
      let path = `/teacher-detail`;
      await this.$router.push({
        path: path,
        query: { id: id },
      });
    },
  },
};
</script>
<style lang="scss">
#allTeacher {
  .VueSlickCarousel,
  div {
    /* padding: 0; */
    /*border-radius: 20px;*/
  }
  .slick-arrow.slick-next,
  .slick-arrow.slick-prev {
    z-index: 999;
    color: #000000;
    border-color: #000000;
    background-color: transparent;
    /* margin-left: 50px;
    margin-right: 50px; */
  }
  .slick-slide {
    /* display: flex !important; */
  }
}
</style>
